import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
// import VideoSource from 'components/VideoSource';
import Rating from 'react-rating';
import './style.scss';
import { Link, withRouter } from 'react-router-dom';
import Hashids from 'hashids/cjs';
import Avatar from '@material-ui/core/Avatar';
import { Badge } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import Icon from '../Icon';
import { saveItem, unsaveItem } from '../../pages/SavedItems/helper';
import ActionIconGroup from '../ActionIconGroup';
import BuyButton from '../BuyButton';
import ModalDialog from '../ModalDialog';
import Product from '../../models/Product';
import { Resize } from '../../utils/imageUtils';
import PlaceholderDark from '../../../config/placeholder-dark.config';
import Placeholder from '../../../config/placeholder.config';
import { Transducer } from '../../utils/transducer';
import Venue from '../../models/Venue';
import { addToast as actionAddToast } from '../../actions';
const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

const defaultImageUrl = Transducer.getPreference('dark_mode') === 'true' ? PlaceholderDark.cigar : Placeholder.cigar;

class ProductCardList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saved: props.saved,
    };
    if (window.analytics) {
      window.analytics.track('Product Impressions', {
        product_id: this.props.item.id,
        venue_id: this.props.item.venue_id,
        user_id: this.props.auth && this.props.auth.user && this.props.auth.user.id,
      });
    }
  }

  showProductDetail = (product) => {
    if (product && product.id) {
      if (window.analytics) {
        window.analytics.track('Product Clicks', {
          product_id: this.props.item.id,
          venue_id: this.props.item.venue_id,
          user_id: this.props.auth && this.props.auth.user && this.props.auth.user.id,
        });
      }
      this.props.history.push({ pathname: `/products/${hashids.encode(product.id)}`, state: { product: JSON.parse(JSON.stringify(product)) } });
    }
  };

  formatDate = (dateStr) => {
    // TODO Include format like "4 hours ago" or "1 day ago" max
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', options); // TODO How to get user's locale?
  };

  toggleSavedItem = () => {
    const product = this.props.item;
    const item = JSON.parse(JSON.stringify(product));
    const { auth } = this.props;
    const { user } = auth;

    console.log('Toggling saved item for state:');
    if (!this.state.saved) {
      console.log('save');
      saveItem(user, 'product', item);
    } else {
      console.log('unsave');
      unsaveItem(user, {
        item_id: item.id,
        type: 'product',
      });
    }
    this.setState((prevState) => ({ saved: !prevState.saved }), () => {
      this.props.addToast({
        content: (<>{this.state.saved ? 'Saved to Products' : 'Removed from Products'}</>),
        duration: 6000,
      });
    });
  };

  shareTo = (service) => {
    const product = this.props.item;
    let url = `${config.shareUrl}/p/${product.id}`;
    if (service === 'facebook') {
      url = `https://www.facebook.com/sharer.php?u=${url}`;
    } else if (service === 'twitter') {
      url = `https://twitter.com/share?url=${url}&text=${product.name}`;
    } else if (service === 'reddit') {
      url = `https://reddit.com/submit?url=${url}&title=${product.name}`;
    } else if (service === 'pinterest') {
      url = `https://pinterest.com/pin/create/button/?url=${url}&media=${product.image_url}&description=${product.name}`;
    }

    const shareWindow = window.open(url, 'share-popup', 'height=350,width=600');
    if (shareWindow && shareWindow.focus) { shareWindow.focus(); }
    return false;
  };

  renderSponsorBadge = () => (
    <Badge
      pill
      style={{ marginLeft: 10, color: '#9f9f9f' }}
      className="card-header-badge"
      onClick={() => {
        ModalDialog.show({
          title: 'Sponsored',
          message: 'You\re seeing this ad because of its relevancy to the search filters and/or your personal cigar preferences.',
        });
      }}
    >
      {'Sponsored'}
    </Badge>
  );

  renderAffiliateBadge = (withMargin = true) => (
    <Badge
      pill
      style={{ marginLeft: withMargin ? 10 : 0, color: '#9f9f9f' }}
      className="card-header-badge"
      onClick={() => {
        ModalDialog.show({
          title: 'Affiliated Partner',
          message: 'This business is an affiliated partner. That means that if you follow a link to their website from ours through a button, banner ad, or any other means and buy any of the products they offer, we\'ll receive a small commission. This commission is not charged to you, the customer, and we only partner with sites we trust.',
        });
      }}
    >
      {'Affiliated Partner'}
    </Badge>
  );

  renderHeader = () => {
    const product = this.props.item;
    const venue = product.venue || {};
    return (
      <div style={{ display: 'flex', padding: 5 }} className="feed-session-card-header">
        <Link to={`/venues/${hashids.encode(venue.id)}`}>
          <Avatar src={Venue.getImage(venue)} style={{ height: 28, width: 28, margin: '8px 12px' }}>{venue.name && (venue.name.charAt(0) || 'B')}</Avatar>
        </Link>
        <div style={{ flex: 1 }}>
          <div>
            <Link to={`/venues/${hashids.encode(venue.id)}`}>
              <span style={{ fontWeight: 600, color: '#2b2b2b !important' }}>{venue.name}</span>
            </Link>
            {(product.affiliate_url || product.affiliateUrl) && this.renderAffiliateBadge()}
          </div>
          <div style={{ fontSize: 12 }}>{(venue.location && venue.location.formatted_address) || venue.website}</div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="Save product"
          style={{ marginRight: 5 }}
          onClick={this.toggleSavedItem}
        >
          <Icon name={this.state.saved ? 'bookmark' : 'bookmark-border'} vendor="material" />
        </IconButton>
      </div>
    );
  };

  renderMedia = () => (
    <div className="image" style={{ cursor: 'pointer' }}>
      { !this.props.hideHeader && this.renderHeader() }
      { this.renderInnerMedia() }
    </div>
  );

  renderInnerMedia = () => {
    const product = this.props.item;
    const { orientation } = this.props;
    const imageURL = Product.getImage(product);
    if (imageURL || orientation === 'horizontal') {
      return (
        <div className="image" onClick={() => this.showProductDetail(product)}>
          <div
            className="img"
            style={{
              backgroundImage: `url("${Resize.size(imageURL, {
                width: 500,
                height: 500,
                cropType: 'fit',
                additionalParams: '&pad=true&bg_color=ffffff',
              })}"), url("${defaultImageUrl}")`,
              width: orientation === 'horizontal' ? '35vw' : null,
              // maxWidth: 300,
            }}
          />
        </div>
      );
    }
    return null;
  };

  // FIXME As previously discussed, consider using product.contents[0].cigar.full_name when available for consistency and
  //  then fall back to the name otherwise - we don't want the same cigar shown with multiple variations of its name
  productTitle = () => {
    const product = this.props.item;
    if (product.packaging && product.packaging.type !== 'sampler') {
      if (product.contents && product.contents.length === 1 && product.contents[0].cigar && product.contents[0].vitola) {
        return `${product.contents[0].cigar.full_name} ${product.contents[0].vitola.formatted_name}`;
      }
      if (product.contents && product.contents.length === 1 && product.contents[0].vitola) {
        return `${product.name} ${product.contents[0].vitola.formatted_name}`;
      }
    }
    return product.name;
  };

  productPackaging = () => {
    const product = this.props.item;
    if (product.packaging) {
      let qty = parseInt(product.packaging.quantity);
      if (product.packaging.type === 'Sampler' && product.contents) {
        for (let i = 0; i < product.contents.length; i++) {
          qty += product.contents[i].quantity;
        }
      }
      if (qty > 1) {
        return `${product.packaging.type} of ${qty}`;
      }
      return `${product.packaging.type}`;
    }
    return null;
  }

  renderActionIcons = () => (
    <ActionIconGroup
      comments={false}
      containerStyle={{ float: 'right', marginRight: isMobile ? 10 : 0, right: isMobile ? 0 : 40, bottom: isMobile ? 0 : 10, top: isMobile ? -36 : 0, position: isMobile ? 'relative' : 'absolute' }}
      toggleSavedItem={this.toggleSavedItem}
      // itemSaved={this.state.isOnSavedList}
      shareTo={this.shareTo}
    />
  );

  // FIXME Rewrite this for header
  // renderShopInfo = () => {
  //   const cigar = this.props.item;
  //   if (isMobile) {
  //     return (
  //       <div className="products" style={{ position: 'absolute', bottom: 20, marginRight: 20, borderTop: '1px solid #efefef', paddingTop: 10 }}>
  //         Sold by
  //         <a href={`https://sigaro-shop.com/module/trylist/productlisting?c=${cigar.id}`}>
  //           <img src="http://cdn.sigaro.io/assets/img/shop-logo.svg" alt="Sigaro Smoke Shop" className="shop-logo" />
  //         </a>
  //       </div>
  //     );
  //   }
  //   return (
  //     <div className="products" style={{ float: 'left', marginBottom: 10 }}>
  //       Sold by
  //       <a href={`https://sigaro-shop.com/module/trylist/productlisting?c=${cigar.id}`}>
  //         <img src="http://cdn.sigaro.io/assets/img/shop-logo.svg" alt="Sigaro Smoke Shop" className="shop-logo" />
  //       </a>
  //     </div>
  //   );
  // };

  render() {
    const { orientation } = this.props;
    const product = this.props.item;
    // console.log(product);
    let desc = '';

    if (product.description) {
      desc = `${product.description.substring(0, 250)}...`;
    }

    let price; const
      { msrp } = product;
    if (product.sale_price) {
      price = product.sale_price;
    }

    const savings = msrp ? `${(msrp > price ? (1 - (price / msrp)) * 100 : 0).toFixed(0)}%` : 0;

    if (orientation === 'horizontal') {
      return (
        <div className="feed-card-item">
          { this.renderHeader() }
          <div style={{ display: 'flex', flexDirection: orientation === 'horizontal' ? 'row' : 'column' }}>
            <div className="image-wrapper">
              { this.renderInnerMedia() }
            </div>
            <div className="content">
              <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
                {product.sponsored && this.renderSponsorBadge()}
                {this.props.hideHeader && (product.affiliate_url || product.affiliateUrl) && this.renderAffiliateBadge(false)}
                <h3
                  onClick={() => this.showProductDetail(product)}
                  style={{ marginBottom: 0, cursor: 'pointer', marginTop: (product.affiliate_url || product.affiliateUrl) ? 0 : 'inherit' }}
                >
                  {this.productTitle()}
                </h3>
                <div>{this.productPackaging()}</div>
                <div>
                  <Rating
                    initialRating={product.avg_rating / 20}
                    emptySymbol="far fa-star"
                    fullSymbol="fas fa-star"
                    fractions={2}
                    readonly
                    style={{ color: 'rgb(214, 194, 144)', marginBottom: 10 }}
                  />
                  <span style={{ marginLeft: 10 }}>
                    {`${product.rating_count || 0} ratings`}
                  </span>
                  {/* { isMobile && this.renderActionIcons() } */}
                </div>
                <div>
                  <BuyButton
                    product={new Product(product)}
                    showPrefix={false}
                  />
                  {msrp && (msrp > price) && (
                    <div style={{ marginLeft: 10, display: 'inline-block' }}>
                      {'MSRP'}
                      {' '}
                      <span style={{ textDecoration: 'line-through', marginRight: 8 }}>
                        {`$${parseFloat(msrp).toFixed(2)}`}
                      </span>
                      <span>{`(Save ${savings})`}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="feed-card-item">
        { this.renderMedia() }
        <div className="content">
          <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
            {this.props.hideHeader && (product.affiliate_url || product.affiliateUrl) && this.renderAffiliateBadge(false)}
            <h3
              onClick={() => this.showProductDetail(product)}
              style={{ marginBottom: 0, cursor: 'pointer', marginTop: (product.affiliate_url || product.affiliateUrl) ? 0 : 'inherit' }}
            >
              {this.productTitle()}
            </h3>
            <div>{this.productPackaging()}</div>
            <div>
              <Rating
                initialRating={product.avg_rating / 20}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)', marginBottom: 10 }}
              />
              <span style={{ marginLeft: 10 }}>
                {`${product.rating_count || 0} ratings`}
              </span>
              {/* { isMobile && this.renderActionIcons() } */}
            </div>
            <div>
              <BuyButton
                product={new Product(product)}
                showPrefix={false}
              />
              {msrp && (msrp > price) && (
                <div style={{ marginLeft: 10, display: 'inline-block' }}>
                  {'MSRP'}
                  {' '}
                  <span style={{ textDecoration: 'line-through', marginRight: 8 }}>
                    {`$${msrp}`}
                  </span>
                  <span>{`(Save ${savings})`}</span>
                </div>
              )}
            </div>
            {!this.props.hideDescription && <p dangerouslySetInnerHTML={{ __html: desc }} style={{ margin: '10px 10px 0 0' }} />}
          </div>
        </div>

        {/* FIXME Does this kind of thing require lazy loading cards? That's a lot of extra DOM elements loaded when it only really needs to render when it's about to be shown */}
        {/* { this.state.showMoreOptions && this.renderMoreOptionsSheet() } */}
      </div>
    );
  }
}

ProductCardList.propTypes = {
  item: PropTypes.object.isRequired,
  // name: PropTypes.string,
  orientation: PropTypes.string,
};

ProductCardList.defaultProps = {
  orientation: 'grid',
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

const mapDispatchToProps = (dispatch) => ({
  addToast: (data) => dispatch(actionAddToast(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductCardList));
