import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Hashids from 'hashids/cjs';
import { NotificationsStore, SettingsStore } from '../../stores';
const hashids = new Hashids('', 12);

const title = 'Cigars Near Me App';
const description = 'Cigars Near Me provides information like distance, name, address, and phone number of local cigar shops, along with additional details such as email, website, operational hours, onsite smoking availability, brands, events, pictures, and special offers.';
const imageUrl = 'https://cdn.boxpressd.io/assets/img/open-graph-banner.jpg';
const url = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;

function PageMeta(props) {
  const count = NotificationsStore.useState((s) => s.notificationCount);
  const darkMode = SettingsStore.useState((s) => s.darkMode);
  const notificationCount = count ? `(${count}) ` : '';
  return (
    <div>
      <Helmet>
        {/* General tags */}
        <title>{`${notificationCount}${props.title && props.title.length ? `${props.title} | Cigars Near Me App` : title}`}</title>
        <meta name="description" content={props.description && props.description.length ? props.description : description} />
        <meta name="theme-color" content={darkMode ? '#17191d' : '#ffffff'} />
        {window.location.hostname !== 'app.cigarsnearme.com' && window.location.hostname !== 'cigarsnearme.com' && <meta name="robots" content="noindex, nofollow" />}
        {(window.location.hostname === 'app.cigarsnearme.com' || window.location.hostname === 'cigarsnearme.com') && <meta name="robots" content="max-image-preview:large" />}
        {/* TODO Include an RSS feed for the main app public feed? */}
        <link rel="alternate" type="application/rss+xml" href="https://cnm-wp.com/feed" />
        <link rel="canonical" href={props.canonical || `https://app.boxpressd.com${window.location.pathname}`} />
        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={props.title || title} />
        <meta property="og:description" content={props.description || description} />
        <meta property="og:image" content={props.imageUrl || imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="357163961318411" />
        {/* Twitter Card tags */}
        <meta property="twitter:title" content={props.title || title} />
        <meta property="twitter:description" content={props.description || description} />
        <meta property="twitter:domain" content="boxpressd.com" />
        <meta property="twitter:url" content={url} />
        {/* FIXME We can use a slightly smaller image for Twitter - 150x150 should work */}
        <meta property="twitter:image" content={props.imageUrl || imageUrl} />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:creator" content="@boxpressd" />
        {props.avgRating && props.ratingCount && (
          <div itemProp="aggregateRating" itemType="https://schema.org/AggregateRating" itemScope>
            <meta itemProp="reviewCount" content={`${props.ratingCount}`} />
            <meta itemProp="ratingValue" content={`${props.avgRating}`} />
            {/* TODO itemReviewed as Thing? - see https://search.google.com/test/rich-results/result/r%2Freview-snippet?id=tS7CdV2XkjxA0_MvMk-EiQ for FB profile */}
          </div>
        )}
        {props.reference && (
          <meta itemProp="sku" content={props.reference} />
        )}
        {props.brand && (
          <div itemProp="brand" itemType="https://schema.org/Brand" itemScope>
            <meta itemProp="name" content={props.brand} />
          </div>
        )}
        {props.type && (
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': props.type, // Product, Event, Store? - what for Bars etc? How to know if it's a Restaurant?
              name: props.title || title,
              headline: props.type === 'Article' && (props.title || title),
              image: [props.imageUrl || imageUrl],
              author: {
                '@type': 'Organization',
                name: 'Boxpressd',
              },
              description: props.description || description,
              // "keywords": "comma, separated, list",
              startDate: props.startDate,
              endDate: props.endDate,
              telephone: props.phone,
              geo: (props.coordinates && props.coordinates.latitude && props.coordinates.longitude) && {
                '@type': 'GeoCoordinates',
                latitude: props.coordinates.latitude,
                longitude: props.coordinates.longitude,
              },
              address: props.address && {
                '@type': 'PostalAddress',
                streetAddress: props.address.address1,
                addressLocality: props.address.city,
                postalCode: props.address.zip,
                addressRegion: props.address.state,
                addressCountry: props.address.country || 'US',
              },
              // openingHoursSpecification: {
              //   ...https://developers.google.com/search/docs/advanced/structured-data/local-business
              // },
              eventAttendanceMode: props.eventAttendanceMode,
              eventStatus: props.eventStatus,
              location: props.location && typeof props.location === 'object' && !props.location['@type'] ? {
                '@type': 'Place',
                name: props.location.name,
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: props.location.location && props.location.location.address1,
                  addressLocality: props.location.location && props.location.location.city,
                  postalCode: props.location.location && props.location.location.zip,
                  addressRegion: props.location.location && props.location.location.state,
                  addressCountry: (props.location.location && props.location.location.country) || 'US',
                },
              } : props.location,
              organizer: props.organizer && {
                '@type': 'Organization',
                name: props.organizer.name,
                url: `https://${window.location.hostname}/${props.organizer.type}/${hashids.encode(props.organizer.id)}`,
              },
              aggregateRating: props.avgRating && props.ratingCount ? {
                '@type': 'AggregateRating',
                ratingValue: props.avgRating,
                ratingCount: props.ratingCount,
              } : undefined,
              offers: props.offers ? props.offers.map((offer) => ({
                '@type': 'Offer',
                url: offer.url || url,
                name: offer.name,
                priceCurrency: 'USD',
                price: offer.salePrice || offer.sale_price || offer.msrp || offer.price,
                itemCondition: 'https://schema.org/NewCondition',
                availability: `https://schema.org/${offer.quantity === 0 ? 'OutOfStock' : 'InStock'}`,
              })) : undefined,
              publisher: props.type === 'Article' ? {
                // TODO Make this dynamic? Eventually, we can host other users' articles
                '@type': 'Organization',
                name: 'Boxpressd',
                logo: {
                  '@type': 'ImageObject',
                  url: 'https://cdn.boxpressd.io/logos/launcher/pwa-launch-screen-icon.png',
                },
              } : undefined,
              downloadUrl: props.downloadUrl,
              installUrl: props.installUrl,
              softwareVersion: props.softwareVersion,
              applicationCategory: props.applicationCategory,
            })}
          </script>
        )}
      </Helmet>
      {props.reviews && props.reviews.length > 0 && (
        <Helmet>
          {props.reviews.forEach((review) => {
            if (review.advance_rating) {
              return (
                <div itemProp="review" itemType="https://schema.org/Review" itemScope>
                  {review.user && (
                    <div itemProp="author" itemType="https://schema.org/Person" itemScope>
                      <meta itemProp="name" content={review.user.alias} />
                    </div>
                  )}
                  <div itemProp="reviewRating" itemType="https://schema.org/Rating" itemScope>
                    <meta itemProp="ratingValue" content={`${review.advance_rating.rating}`} />
                    {/* <meta itemProp="bestRating" content="5" /> */}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </Helmet>
      )}
      {props.products && props.products.length > 0 && (
        <Helmet>
          {props.products.map((product) => (
            <div itemProp="offers" itemType="https://schema.org/Offer" itemScope>
              <link itemProp="url" href={url} />
              <meta itemProp="availability" content={`https://schema.org/${product.quantity === 0 ? 'OutOfStock' : 'InStock'}`} />
              <meta itemProp="priceCurrency" content="USD" />
              <meta itemProp="itemCondition" content="https://schema.org/NewCondition" />
              <meta itemProp="price" content={`${product.sale_price}`} />
              {/* <meta itemProp="priceValidUntil" content={product.expiration_timestamp} /> */}
            </div>
          ))}
        </Helmet>
      )}
      {props.children}
    </div>
  );
}

PageMeta.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  phone: PropTypes.string,
  ratingCount: PropTypes.number,
  avgRating: PropTypes.number,
  reviews: PropTypes.array,
  products: PropTypes.array,
  reference: PropTypes.string,
  brand: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  address: PropTypes.object,
  location: PropTypes.object,
  organizer: PropTypes.object,
  coordinates: PropTypes.object,
  downloadUrl: PropTypes.string,
  installUrl: PropTypes.string,
  softwareVersion: PropTypes.string,
  applicationCategory: PropTypes.string,
  eventAttendanceMode: PropTypes.string,
};

export default PageMeta;
