import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import * as Sentry from '@sentry/browser';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'mobile-device-detect';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/core';
import { SettingsStore } from '../../stores';
import Icon from '../Icon';
import ModalView from '../ModalView';
import { history } from '../../utils/browserRouter';
import { Transducer } from '../../utils/transducer';

// FIXME Why won't this work??? Tried a bunch of different namings - for now, it's in the main _base.scss
const useStyles = makeStyles(() => ({
  input: {
    color: '#2b2b2b',
  },
}));

// FIXME Try to A/B test the two options and see which convert more
function PushRequestPrompt(props) {
  const classes = useStyles();
  const showPromptSetting = SettingsStore.useState((s) => !s.disablePushRequestPrompt && s.showPushRequestPrompt);
  const [phoneNumber, setPhoneNumber] = useState(null);

  useEffect(() => {
    if (typeof window.OneSignalPlugin.on === 'function') {
      window.OneSignalPlugin.on('subscriptionChange', (isSubscribed) => {
        console.log(props.location.pathname);
        if (isSubscribed && props.location.pathname.indexOf('settings/notifications') === -1) {
          // console.log('Subscribed user');
          history.push('/alerts');
        }
        setShowPrompt(false);
      });
    }
    // FIXME Can we unsubscribe from the event? Do we need to?
  }, []);

  const setShowPrompt = (show) => {
    SettingsStore.update((s) => {
      s.showPushRequestPrompt = show;
    });
  };

  const pushContent = () => (
    <>
      <div style={{ textAlign: 'center', padding: 10 }}>
        <h4 style={{ marginTop: 20 }}>New alert available. Never miss an interaction by enabling push notifications.</h4>
        <img src="//cdn.boxpressd.io/placeholder/saved_items.png" style={{ width: '100%' }} />
        <div style={{ margin: 20 }}>
          <Button
            color="success"
            style={{ width: '100%', marginBottom: 20, fontSize: '1.2rem' }}
            onClick={() => {
              try {
                console.log('Registering for push notifications on OneSignal...');
                window.OneSignalPlugin.registerForPushNotifications();
                // INFO They are signed up for these by default and have to opt-out. We will be able to use more than
                //  10 tags total by keeping these out unless they set it to false
                // window.OneSignalPlugin.sendTags({
                //   social: true,
                //   shopping: true,
                //   events: true,
                //   stock: true,
                //   humidor: true,
                //   cigar_matches: true,
                //   news: true,
                // });
              } catch(e) {
                Sentry.captureException(e);
              }
            }}
          >
            {'Enable and Show'}
          </Button>
          <Button
            outline
            color="primary"
            style={{ width: '100%' }}
            onClick={() => {
              Transducer.setPreference('disable_push_request_prompt', 'true');
              SettingsStore.update((s) => {
                s.disablePushRequestPrompt = 'true';
              });
              setShowPrompt(false);
            }}
          >
            {'Not Now'}
          </Button>
        </div>
      </div>
    </>
  );

  const smsContent = () => (
    <>
      <div style={{ textAlign: 'center', padding: 10 }}>
        <h2 style={{ color: '#ffffff' }}>
          {props.title}
          <sup>*</sup>
        </h2>
        <h4>We'll alert you when you have new friend requests, comments on your posts, or your cigars are out for delivery. Plus, your friends will have an easier time finding you. PS, we NEVER sell your personal information. Period.</h4>
        <img src="//cdn.boxpressd.io/placeholder/saved_items.png" style={{ width: '100%' }} />
        <span>
          <sup>*</sup>
          {' '}
          {'standard messaging rates apply'}
        </span>
      </div>
      <div style={{ padding: 20 }} className="sms-prompt-input">
        <InputMask
          mask="+1\ (999) 999-9999"
          maskChar="_"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        >
          {() => (
            <Input
              value={phoneNumber}
              placeholder="Mobile Number"
              className={classes.input}
              startAdornment={(
                <InputAdornment position="start">
                  <Icon name="smartphone" />
                </InputAdornment>
              )}
              style={{ backgroundColor: '#efefef', padding: 14 }}
              type="tel"
            />
          )}
        </InputMask>
      </div>
      <div style={{ margin: 20 }}>
        <Button
          color="success"
          style={{ width: '100%', marginBottom: 20, fontSize: '1.2rem' }}
          onClick={() => {
            // TODO Sign them up for SMS messages
            // OneSignal.registerForPushNotifications();
            // OneSignal.setPhone(phoneNumber);
            console.log('Receiving SMS notifications through OneSignal');
            window.OneSignalPlugin.setSMSNumber(phoneNumber);
          }}
        >
          {'Allow'}
        </Button>
        <Button
          outline
          color="primary"
          style={{ width: '100%' }}
          onClick={() => {
            console.log('Use Push instead of SMS...');
            Transducer.setPreference('disable_push_request_prompt', 'true');
            SettingsStore.update((s) => {
              s.disablePushRequestPrompt = 'true';
            });
            setShowPrompt(false);
            // TODO Need to show the push prompt if we decide to collect SMS or simply close
            // if (pushSupported()) {
            //   console.log('Prompting for push notifications...');
            //   showPushRequestPrompt();
            // }
          }}
        >
          {'Not Now'}
        </Button>
      </div>
    </>
  );

  if (isMobile) {
    return (
      <ModalView
        open={showPromptSetting}
        showFrom="bottom"
        onClick={() => setShowPrompt(false)}
        onClose={() => setShowPrompt(false)}
      >
        {props.allowSMS ? smsContent() : pushContent()}
      </ModalView>
    );
  }
  return (
    <Modal isOpen={showPromptSetting} toggle={() => setShowPrompt(false)}>
      <ModalBody>
        {props.allowSMS ? smsContent() : pushContent()}
      </ModalBody>
    </Modal>
  );
}

PushRequestPrompt.defaultProps = {
  show: false,
  allowSMS: false,
  title: 'New Alert.\nEnable text updates and never miss an interaction.', // FIXME This should get set based on the type of alert (friend request, comment, etc)
};

export default withRouter(PushRequestPrompt);
