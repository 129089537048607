import React  from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import './style.scss';

function PlusToXIcon(props) {
  const handleClick = () => {
    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  };

  return (
    <div className={`plus-to-x ${props.isOpen ? 'show-x' : ''}`} onClick={handleClick}>
      <Icon name="plus" style={{ color: '#586069' }} />
    </div>
  );
}

PlusToXIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

export default PlusToXIcon;
