import React, { useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authUser } from '../../pages/Auth/actions';
import PageWrap from '../page-wrap';

import './style.scss';
import { maybeUpdateCurrentUser } from '../../utils/axiosAuthHelper';
import { redirectAuth } from '../../utils/redirect';
import { config } from '../../settings';

let shouldCheckAuth = true;
let prevParams = null;

// INFO For now, this isn't being used - see app.js for handling using onEnter prop
function PrivateRoute({ auth, updateAuth, history, component, ...rest }) {
  const [state, setState] = useState({
    loading: false,
    authenticated: false,
  });

  useEffect(() => {
    let shouldRedirect = true;
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      shouldRedirect = false;
      setState({
        loading: true,
        authenticated: false,
      });
    }
    if (auth.authenticated && auth.user) {
      setState({
        loading: false,
        authenticated: true,
      });
    } else {
      if (window.Capacitor.isNative && prevParams !== window.location.search) {
        shouldCheckAuth = true;
        prevParams = window.location.search;
      }
      if (shouldCheckAuth) {
        shouldCheckAuth = false;
        checkAuth().then(() => {
          setState({
            loading: false,
            authenticated: true,
          });
        }).catch(() => {
          setState({
            loading: false,
            authenticated: false,
          });
          if (shouldRedirect) {
            redirect();
          }
        });
      }
    }
  }, [window.location.path, window.location.search]);

  const checkAuth = async () => new Promise((resolve, reject) => {
    console.log('Checking for user token...');
    if (!auth.authenticated || !auth.user) {
      maybeUpdateCurrentUser().then((user, tokenId) => {
        updateAuth(user, tokenId, () => {
          console.log('User SSO successful');
          resolve(true);
        });
      }).catch(() => {
        console.log('No auth token to use, redirecting...');
        reject();
      });
    }
  });

  const redirect = () => {
    console.log('Redirecting to auth...');
    redirectAuth(window.location.href.split('?')[0]);
    return null;
  };

  const renderLoadingIndicator = (inner) => (
    <div className="loader-wrapper">
      <div className="loader">
        <div className="line" />
        <div className="line" />
        <div className="line" />
        <div className="line" />
        {inner()}
      </div>
    </div>
  );

  const renderLoading = () => (
    <PageWrap>
      {renderLoadingIndicator(() => (
        <h4>
          {"'Bout that time..."}
          <span><img src="https://cdn.boxpressd.io/placeholder/boxpressd-liked.png" height={32} width={32} /></span>
        </h4>
      ))}
    </PageWrap>
  );

  const renderAuthorizing = () => (
    <PageWrap>
      {renderLoadingIndicator(() => (
        <h4>
          <p>Just a moment, redirecting you to Boxpressd authentication...</p>
          <p>If you are not redirected automatically, please <a href={`${config.authEndPoint}/login?path=${encodeURI(window.location.href.split('?')[0])}`}>click or tap here</a>.</p>
        </h4>
      ))}
    </PageWrap>
  );

  // FIXME Try again to use 3 states for Firebase auth - we need to wait to be sure they're logged out before redirecting
  //  Also, if there is a token attached, it should "load" until we get an auth change from Firebase. Try to consolidate it
  //  all to one file. It's unfortunate that there are private actions in addition to private routes...
  if (state.loading) {
    return renderLoading();
  }
  if (!state.loading && !state.authenticated) {
    return renderAuthorizing();
  }
  if (!state.loading && state.authenticated) {
    return <Route {...rest} component={component} />;
  }
  return null;
}

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

const mapDispatchToProps = (dispatch) => ({
  updateAuth: (currentUser, tokenId, callback) => dispatch(authUser(currentUser, tokenId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(withRouter(PrivateRoute));
