import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pusher from 'react-pusher';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { requestFeed } from '../../pages/FeedList/helper';
import { withStyles } from '@material-ui/styles';
import { loadChatMessages } from '../../pages/VirtualLounge/actions';
import { FeedStore, SettingsStore, UserStore } from '../../stores';
import { updateSettings as actionUpdateSettings } from '../../actions';
import { config } from '../../settings';
import { updateUser as updateUserAction } from '../../pages/Auth/actions';
import { useAlertStatus } from '../../utils/useSettings';

const snackbarStyles = {
  root: {
    backgroundColor: '#d6c290 !important',
    color: '#ffffff !important',
  },
};

let pushSupported = false;

// SEE https://elite-dev.atlassian.net/browse/BXPR-287
// We need to be able to prompt users to enable push notifications if they are not already enabled when a notification
// comes in that is intended to display as a push notification rather than just a trigger to update some state in the app.
// We should be able to handle this both with Capacitor and directly in the web as OneSignal does, so we can remove OneSignal

// https://blog.agney.dev/one-signal-on-gatsby/
// TODO Should we include push notification (status bar notification via OneSignal) handling here too or is it handled elsewhere already?
function PushController(props) {
  const [alertStatus, setAlertStatus] = useAlertStatus({
    notifications: [],
    notificationCount: 0,
  });
  const { auth } = props;
  const user = auth ? auth.user : null;

  useEffect(() => {
    updatePushSupported();
  }, []);

  // useEffect(() => {
  //   if (user && user.id) {
  //     axios.get(`${config.apiEndPoint}/users/${user.id}/notifications`).then((results) => {
  //       let notificationCount = 0;
  //       for (const notification of results.data) {
  //         if (!notification.read) {
  //           notificationCount += 1;
  //         }
  //       }
  //       setAlertStatus({
  //         notifications: results.data,
  //         notificationCount,
  //       });
  //       if (results.data && results.data.length && !results.data[0].read) {
  //         maybeShowPrompt();
  //       }
  //     }).catch((err) => {
  //       console.error(err);
  //     });
  //   }
  // }, [user]);

  function maybeShowPrompt() {
    console.log('Testing for ability to prompt for push notifications...');
    if (!pushSupported || window.Capacitor.isNative) { return false; }
    console.log('Getting push notification permission state...');
    window.OneSignalPlugin.getNotificationPermission((permission) => {
      console.log('Site Notification Permission:', permission);
      if (permission === 'default') {
        SettingsStore.update((s) => {
          s.showPushRequestPrompt = true;
        });
      }
    });
  }

  function updatePushSupported() {
    console.log(window.Capacitor.isNative || window.OneSignalPlugin.isPushNotificationsEnabled() ? 'Push notifications supported' : 'Push notifications not supported');
    pushSupported = window.Capacitor.isNative || window.OneSignalPlugin.isPushNotificationsEnabled();
  }

  // FIXME This is the default prompt from OneSignal - for now, I think we're fine using our custom prompt to subscribe them to all categories
  // function showPushRequestPrompt() {
  //   console.log('Showing OneSignal notification slide prompt');
  //   OneSignal.showCategorySlidedown().then(() => {
  //     console.log('Showed OneSignal notification slide prompt');
  //   }).catch((err) => {
  //     console.error(err);
  //   });
  // }

  return (
    <div>
      {props.children}
      {/*{user && (*/}
      {/*  <Pusher*/}
      {/*    channel={`user-${user.id}-notifications`}*/}
      {/*    event="newAlert"*/}
      {/*    onUpdate={(data) => {*/}
      {/*      console.log('Received pusher user alerts notification:');*/}
      {/*      console.log(data);*/}
      {/*      if (window.OneSignalPlugin) {*/}
      {/*        if (typeof window.OneSignalPlugin.setExternalUserId === 'function') {*/}
      {/*          window.OneSignalPlugin.setExternalUserId(`${user.id}`);*/}
      {/*        }*/}
      {/*        if (typeof window.OneSignalPlugin.setEmail === 'function') {*/}
      {/*          window.OneSignalPlugin.setEmail(user.email);*/}
      {/*        }*/}
      {/*      }*/}
      {/*      // FIXME Why would it not be an array here??? Getting 'unshift is not a function' without this logic in place*/}
      {/*      setAlertStatus({*/}
      {/*        notifications: Array.isArray(alertStatus.notifications) ? alertStatus.notifications.unshift(data.notification) : [data.notification],*/}
      {/*        notificationCount: (alertStatus.notificationCount || 0) + 1,*/}
      {/*      });*/}
      {/*      // FIXME The badge for the menu icon doesn't seem to show unless we click outside of the prompt - trigger a click event programmatically to hack it?*/}
      {/*      maybeShowPrompt();*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{user && (*/}
      {/*  <Pusher*/}
      {/*    channel={`user-${user.id}`}*/}
      {/*    event="updateAvailable"*/}
      {/*    onUpdate={async () => {*/}
      {/*      await axios.get(`${config.apiEndPoint}/users/${user.id}`).then((response) => {*/}
      {/*        const updatedUser = response.data;*/}
      {/*        if (updatedUser) {*/}
      {/*          props.updateUser(updatedUser);*/}
      {/*        }*/}
      {/*      });*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{user && (*/}
      {/*  <Pusher*/}
      {/*    channel={`user-${user.id}-saved-items`}*/}
      {/*    event="updateAvailable"*/}
      {/*    onUpdate={() => {*/}
      {/*      console.log('Received pusher user saved items notification:');*/}
      {/*      // TODO Update saved items?*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*/!* FIXME Ones like this can probably be merged in with the general push notifications - only ones like DMs or feed updates should be their own *!/*/}
      {/*{user && (*/}
      {/*  <Pusher*/}
      {/*    channel={`user-${user.id}-friends`}*/}
      {/*    event="newRequest"*/}
      {/*    onUpdate={() => {*/}
      {/*      console.log('Received pusher user friends notification:');*/}
      {/*      axios.get(`${config.apiEndPoint}/users/${user.id}/friends/pending`).then((response) => {*/}
      {/*        // console.log(response.data);*/}
      {/*        UserStore.update((s) => {*/}
      {/*          s.incomingRequests = response.data;*/}
      {/*          s.friendRequestCount = response.data.length;*/}
      {/*        });*/}
      {/*      }).catch((err) => {*/}
      {/*        console.log(err);*/}
      {/*      });*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*/!* FIXME Ones like this can probably be merged in with the general push notifications - only ones like DMs or feed updates should be their own *!/*/}
      {/*{user && (*/}
      {/*  <Pusher*/}
      {/*    channel={`user-${user.id}-comments`}*/}
      {/*    event="newComment"*/}
      {/*    onUpdate={() => {*/}
      {/*      console.log('Received pusher user comment notification:');*/}
      {/*      // TODO Set global state to display badge*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{user && (*/}
      {/*  <Pusher*/}
      {/*    channel={`user-${user.id}-messages`}*/}
      {/*    event="newMessage"*/}
      {/*    onUpdate={(data) => {*/}
      {/*      console.log('Received pusher user chat message notification:');*/}
      {/*      console.log(data);*/}
      {/*      // TODO Set global state to display badge - this should route them to the virtual humidor*/}
      {/*      // FIXME These should just get appended in the store, don't call all messages again*/}
      {/*      props.loadMessages(data.message.user_id);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{user && (*/}
      {/*  <Pusher*/}
      {/*    channel={`user-${user.id}-groups`} // FIXME What's the best approach for this?*/}
      {/*    event="newPost"*/}
      {/*    onUpdate={() => {*/}
      {/*      console.log('Received pusher group post notification:');*/}
      {/*      // TODO Set global state to display badge*/}
      {/*    }}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.get('auth').toJS(),
});

const mapDispatchToProps = (dispatch) => ({
  loadMessages: (userId, conversationId) => dispatch(loadChatMessages(userId, conversationId)),
  updateSettings: (settings) => dispatch(actionUpdateSettings(settings)),
  updateUser: (user) => dispatch(updateUserAction(user)),
});

export default withStyles(snackbarStyles)(connect(mapStateToProps, mapDispatchToProps)(PushController));
