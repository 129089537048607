import React, { useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Icon from '../Icon';
import SearchResults from '../search-results';
import MobileSearchBar from '../MobileSearchBar';

function QuickActionSearchModal(props) {
  useEffect(() => {
    if (props.open) {
      // TODO search bar should take focus - just use jQuery for now?
    }
  }, [props.open]);

  return (
    <Modal
      isOpen={props.open}
      toggle={props.closeModal}
      style={{ maxWidth: 600 }}
      fade
    >
      <div className="modal-header">
        <Button className="close" color="" onClick={props.closeModal}>
          <Icon name="x" />
        </Button>
      </div>
      <ModalBody>
        {/* FIXME Would be nice to restrict the placeholder text to what they are actually searching (cigars, venues, etc) */}
        <MobileSearchBar
          placeholder="Search…"
          onQueryChange={props.onQueryChange}
        />
        <SearchResults
          height={600}
          onSelect={props.closeModal}
          hideTabs
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.closeModal}>Cancel</Button>
        {/* INFO Right now, clicking the row selects and moves on, no need for next button */}
        {/*<Button color="primary" onClick={props.onSelect}>Next</Button>*/}
      </ModalFooter>
    </Modal>
  );
}

export default QuickActionSearchModal;
